import React from "react";
import "./ComingSoon.css";

const Comingsoon = () => {
  return (
    <div>
      <h2>Coming Soon . . . . .</h2>
    </div>
  );
};

export default Comingsoon;
